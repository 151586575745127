<template>
  <el-popover
    width="200"
    trigger="click"
    v-model="visible">
    <div>
      <div class="title">{{title}}</div>
      <div class="btn-group">
        <el-button size="mini" plain @click="handleCanel">取消</el-button>
        <el-button class="ml16" size="mini" type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </div>
    <div slot="reference">
      <slot name="reference"></slot>
    </div>
  </el-popover>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    handleCanel() {
      this.visible = false
      this.$emit('cancel')
    },
    handleConfirm() {
      this.visible = false
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  color: #000;
}
.btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
</style>